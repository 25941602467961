import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { f } from 'styles';

const SVG = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.00003 2.8944e-05C2.68632 2.86543e-05 2.85662e-05 2.68632 2.82765e-05 6.00003L2.91348e-05 18C2.88451e-05 21.3137 2.68632 24 6.00003 24L18 24C21.3137 24 24 21.3137 24 18L24 6.00003C24 2.68632 21.3137 2.83754e-05 18 2.80857e-05L6.00003 2.8944e-05ZM12 7.41644C10.9983 7.41644 10.0591 7.68425 9.25015 8.15217L9.25015 5.73619C9.25015 4.9768 8.63454 4.36119 7.87515 4.36119C7.11576 4.36119 6.50015 4.97679 6.50015 5.73618L6.50015 12.8803L6.50003 12.9164L6.50015 12.9526L6.50015 13.3751C6.50015 13.5312 6.52615 13.6811 6.57405 13.821C7.00544 16.4282 9.27054 18.4164 12 18.4164C15.0376 18.4164 17.5 15.954 17.5 12.9164C17.5 9.87888 15.0376 7.41644 12 7.41644ZM12.0001 10.1664C10.4821 10.1664 9.2514 11.3963 9.25015 12.914L9.25015 12.9164L9.25015 12.9187C9.2514 14.4364 10.4821 15.6664 12.0001 15.6664C13.5189 15.6664 14.7501 14.4351 14.7501 12.9164C14.7501 11.3976 13.5189 10.1664 12.0001 10.1664Z" fill="#B2BFD1"/>
</svg>
`;

const IconMenuBPay: React.FC<{ onClick?: VoidFunction; css?: Interpolation<Theme> }> = ({ onClick, css }) => (
  <div css={[f.flex, css]} onClick={onClick} dangerouslySetInnerHTML={{ __html: SVG }} />
);

export default IconMenuBPay;
