import React from 'react';

const Login = () => {
  return (
    <main css={{ padding: '20px' }}>
      <h1>this is login page</h1>
    </main>
  );
};

export default Login;
