import React from 'react';
import { title } from './style';

const PurchaseTicket = () => {
  return (
    <main css={{ padding: '20px' }}>
      <h1 css={title}>this is PurchaseTicket page (before user loggedin)</h1>
    </main>
  );
};

export default PurchaseTicket;
