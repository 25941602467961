import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { f } from 'styles';

const SVG = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.1429 12C23.1429 18.154 18.154 23.1429 12 23.1429H2.92483C2.28138 23.1429 1.95966 23.1429 1.77889 23.0131C1.6146 22.8952 1.51923 22.7336 1.49538 22.5328C1.46914 22.3118 1.63663 22.0084 1.9716 21.4015C2.06748 21.2278 2.13986 21.0766 2.19358 20.9349C2.50424 20.1161 2.54119 19.8524 2.46764 18.9797C2.42528 18.477 2.09717 17.4509 1.44096 15.3986C1.10358 14.3435 0.857143 13.1717 0.857143 12C0.857143 5.84597 5.84597 0.857147 12 0.857147C18.154 0.857147 23.1429 5.84597 23.1429 12ZM5.93331 9.52382C5.93331 8.90842 6.4322 8.40953 7.0476 8.40953H16.9524C17.5678 8.40953 18.0666 8.90842 18.0666 9.52382C18.0666 10.1392 17.5678 10.6381 16.9524 10.6381H7.0476C6.4322 10.6381 5.93331 10.1392 5.93331 9.52382ZM5.93331 14.4761C5.93331 13.8607 6.4322 13.3619 7.0476 13.3619H12C12.6154 13.3619 13.1143 13.8607 13.1143 14.4761C13.1143 15.0915 12.6154 15.5904 12 15.5904H7.0476C6.4322 15.5904 5.93331 15.0915 5.93331 14.4761Z" fill="#B2BFD1"/>
</svg>
`;

const IconMenuCommunity: React.FC<{ onClick?: VoidFunction; css?: Interpolation<Theme> }> = ({ onClick, css }) => (
  <div css={[f.flex, css]} onClick={onClick} dangerouslySetInnerHTML={{ __html: SVG }} />
);

export default IconMenuCommunity;
