import React from 'react';
import { f } from 'styles';

const SVG = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM7.94176 8.66368C7.75601 8.14357 7.18379 7.87252 6.66368 8.05828C6.14357 8.24403 5.87252 8.81625 6.05828 9.33636L6.65244 11H5.99998C5.4477 11 4.99998 11.4477 4.99998 12C4.99998 12.5523 5.4477 13 5.99998 13H6.99998C7.12121 13 7.23739 12.9784 7.34491 12.9389L8.55828 16.3364C8.69723 16.7254 9.06114 16.989 9.47414 16.9997C9.88714 17.0104 10.2642 16.766 10.4231 16.3846L12 12.6L13.5769 16.3846C13.7358 16.766 14.1129 17.0104 14.5259 16.9997C14.9389 16.989 15.3028 16.7254 15.4418 16.3364L16.6551 12.9389C16.7626 12.9784 16.8788 13 17 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H17.3476L17.9418 9.33636C18.1275 8.81625 17.8565 8.24403 17.3364 8.05828C16.8162 7.87252 16.244 8.14357 16.0583 8.66368L14.4282 13.2278L12.9231 9.6154C12.7678 9.24276 12.4037 9.00002 12 9.00002C11.5963 9.00002 11.2322 9.24276 11.0769 9.6154L9.57179 13.2278L7.94176 8.66368Z" fill="#00BDC9"/>
</svg>
`;

const IconRequestPayment: React.FC<{ onClick?: VoidFunction; className?: string }> = ({ onClick, className }) => (
  <div css={[f.flex]} className={className} onClick={onClick} dangerouslySetInnerHTML={{ __html: SVG }} />
);

export default IconRequestPayment;
