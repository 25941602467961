import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { f } from 'styles';

const SVG = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.7705 20.1491C25.1458 19.0566 24.401 18.0834 24.401 14.4111V13.6522C24.401 8.91611 20.6611 5.03489 16.0641 5.00021C16.0426 5.00011 16.0213 5 15.9998 5C13.7692 5.00295 11.6311 5.90598 10.0558 7.51045C8.48049 9.11492 7.5971 11.2894 7.59993 13.5556V14.4111C7.59993 18.0831 6.85472 19.0562 6.22968 20.1487C6.08039 20.4085 6.00117 20.7037 6.00001 21.0046C5.99886 21.3054 6.07581 21.6013 6.2231 21.8623C6.37039 22.1233 6.58283 22.3402 6.83897 22.4912C7.09512 22.6421 7.38593 22.7218 7.68206 22.7222H11.7893C11.7893 23.8568 12.2329 24.9448 13.0226 25.7471C13.8123 26.5493 14.8833 27 16 27C17.1168 27 18.1878 26.5493 18.9775 25.7471C19.7672 24.9448 20.2108 23.8568 20.2108 22.7222H24.318C24.6141 22.7218 24.9049 22.6422 25.161 22.4912C25.4171 22.3403 25.6295 22.1234 25.7768 21.8624C25.9241 21.6015 26.0011 21.3057 26 21.0049C25.9989 20.7041 25.9197 20.4089 25.7705 20.1491ZM16 25.2889C15.3302 25.2881 14.6881 25.0174 14.2144 24.5363C13.7408 24.0551 13.4744 23.4027 13.4736 22.7222H18.5265C18.5257 23.4027 18.2593 24.0551 17.7857 24.5363C17.312 25.0174 16.6699 25.2881 16 25.2889Z" fill="#6E7D9D"/>
<rect x="14" width="16" height="16" rx="8" fill="#FF4848"/>
<path d="M22.6621 4.42969H21.4414L19.6934 5.5625V6.72461L21.3438 5.66992H21.3828V11.5H22.6621V4.42969Z" fill="white"/>
</svg>
`;

const IconBellActive: React.FC<{ onClick?: VoidFunction; css?: Interpolation<Theme> }> = ({ onClick, css }) => (
  <div css={[f.flex, css]} onClick={onClick} dangerouslySetInnerHTML={{ __html: SVG }} />
);

export default IconBellActive;
