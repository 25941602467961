import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { f } from 'styles';

const SVG = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.32851e-06C1.34315 2.18366e-06 1.63103e-06 1.34315 1.48619e-06 3L2.16962e-06 17C2.02477e-06 18.6569 1.34315 20 3 20L17 20C18.6569 20 20 18.6569 20 17L20 3C20 1.34315 18.6569 1.78993e-06 17 1.64508e-06L3 2.32851e-06ZM10.2074 5.94916C9.25901 5.94916 8.36979 6.2027 7.60387 6.64571L7.60387 4.35808C7.60387 3.63906 7.02099 3.05618 6.30197 3.05618C5.58295 3.05618 5.00007 3.63906 5.00007 4.35808L5.00007 11.1081L4.99985 11.1568L5.00007 11.2054L5.00007 11.5909C5.00007 11.7383 5.0246 11.8801 5.06978 12.0123C5.47785 14.4813 7.62275 16.3643 10.2074 16.3643C13.0835 16.3643 15.415 14.0328 15.415 11.1568C15.415 8.28068 13.0835 5.94916 10.2074 5.94916ZM10.2078 8.55288C8.76978 8.55288 7.60402 9.71864 7.60402 11.1567C7.60402 12.5947 8.76978 13.7605 10.2078 13.7605C11.6459 13.7605 12.8116 12.5947 12.8116 11.1567C12.8116 9.71864 11.6459 8.55288 10.2078 8.55288Z" fill="white"/>
</svg>
`;

const IconBpay: React.FC<{ onClick?: VoidFunction; css?: Interpolation<Theme> }> = ({ onClick, css }) => (
  <div css={[f.flex, css]} onClick={onClick} dangerouslySetInnerHTML={{ __html: SVG }} />
);

export default IconBpay;
